<template>
  <div class="chat">
    <div class="user-info flex">
      <div class="serviceName" @click="$router.go(-1)">在线咨询</div>
    </div>

    <Chat class="box"></Chat>
  </div>
</template>

<script>
import Chat from '@/components/chat/index'
export default {
  components: { Chat }
}
</script>

<style lang="less" scoped>
.chat {
  display: flex;
  flex-direction: column;
  .user-info {
    padding: 15px 15px;
    background-color: #ffffff;
    .serviceName {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 15px;
      font-size: 15px;
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        left: 0;
        height: 10px;
        width: 10px;
        border-width: 2px 2px 0 0;
        border-color: #000;
        border-style: solid;
        -webkit-transform: matrix(-0.8, 0.8, 0.8, 0.8, 0, 0);
        transform: matrix(-0.8, 0.8, 0.8, 0.8, 0, 0);
      }
    }
  }

  /deep/ .box {
    flex: 1;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    .chat-content {
      height: 80vh;
    }
    .chat_input {
      height: 15vh;
    }
  }
}
</style>